import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store.js'

import PublicRoutes from './PublicRoutes'
import AdminRoutes from './AdminRoutes'

Vue.use(VueRouter)

const routes = [
  ...PublicRoutes,
  ...AdminRoutes
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from) => {
  if (to.meta?.title) {
    Vue.nextTick(() => {
      document.title = to.meta.title
    })
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta?.login === true && !store.auth.user) {
    return next(`/admin/login?redirect=${to.path}`)
  }

  if (to.meta?.login === false && store.auth.user) {
    return next('/admin')
  }

  next()
})

export default router
