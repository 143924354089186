<template>
  <div class="pt-4 px-8 absolute top-0 left-0 right-0 z-30">
    <div class="bg-primary text-black rounded-lg p-2 flex-row hidden sm:flex">
      <div class="mx-2 flex flex-1">
        <router-link to="/" class="w-full text-center hover:underline">
          <font-awesome-icon icon="home" />
          <span class="ml-2">Home</span>
        </router-link>
      </div>
      <div class="mx-2 flex flex-1">
        <router-link to="/recepten" class="w-full text-center hover:underline">
          <font-awesome-icon icon="utensils" />
          <span class="ml-2">Recepten</span>
        </router-link>
      </div>
      <div class="mx-2 flex flex-1">
        <router-link to="/favorieten" class="w-full text-center hover:underline">
          <font-awesome-icon icon="heart" />
          <span class="ml-2">Favorieten</span>
        </router-link>
      </div>
      <div v-if="showUser" class="mx-2">
        <router-link to="/admin/login">
          <font-awesome-icon icon="user" />
        </router-link>
      </div>
    </div>

    <div
      class="z-50 sm:hidden"
      :class="[open ? 'fixed top-0 left-0 right-0 px-8 pt-4' : 'relative']"
    >
      <div class="w-full flex justify-center items-center relative">
        <router-link to="/" class="w-full max-w-xs px-14">
          <img src="@/assets/logo/logo_transparent.svg" alt="Logo Koken met Kat" />
        </router-link>

        <div class="absolute top-0 right-0 bottom-0 flex items-center">
          <div
            class="cursor-pointer"
            :class="{ 'change': open }"
            @click="toggleMenu"
          >
            <div class="bar1"></div>
            <div class="invis"></div>
            <div class="bar3"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="open" class="fixed inset-0 bg-secondary z-40 flex flex-col items-center justify-center">
      <div @click="navigate('/')" class="w-full text-center text-3xl">
        <span class="ml-2 uppercase font-light">Home</span>
      </div>
      <div @click="navigate('/recepten')" class="w-full text-center text-3xl mt-16">
        <span class="ml-2 uppercase font-light">Recepten</span>
      </div>
      <div @click="navigate('/favorieten')" class="w-full text-center text-3xl mt-16">
        <span class="ml-2 uppercase font-light">Favorieten</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar3 {
  width: 35px;
  height: 2px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.invis {
  width: 35px;
  height: 2px;
  margin: 6px 0;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-5px, 6px);
  transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-5px, -6px);
  transform: rotate(45deg) translate(-5px, -6px);
}
</style>

<script>
import store from '../store'

export default {
  data () {
    return {
      open: false
    }
  },
  computed: {
    showUser () {
      return !!store.auth.user
    }
  },
  watch: {
    open (value) {
      if (value) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        document.documentElement.classList.add('overflow-hidden')
      } else {
        document.documentElement.classList.remove('overflow-hidden')
      }
    }
  },
  methods: {
    toggleMenu () {
      this.open = !this.open
    },
    navigate (path) {
      if (path !== this.$route.path) {
        this.$router.push({ path })
      }
      this.open = false
    }
  }
}
</script>
