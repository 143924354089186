export default [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    meta: { title: 'Koken met Kat - Kind of healthy recipes made by Kat' },
    component: () => import('@/views/Home.vue')
  }, {
    path: '/recept/:slug',
    name: 'Recept',
    component: () => import('@/views/Recipe.vue')
  }, {
    path: '/favorieten',
    name: 'Favorieten',
    meta: { title: 'Koken met Kat - Kind of healthy recipes made by Kat' },
    component: () => import('@/views/Favorites.vue')
  }, {
    path: '/recepten',
    name: 'Recepten',
    meta: { title: 'Koken met Kat - Kind of healthy recipes made by Kat' },
    component: () => import('@/views/Recipes.vue')
  }
]
