export default [
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('@/views/Admin/Login.vue'),
    meta: { login: false }
  }, {
    path: '/admin',
    name: 'Admin overview',
    component: () => import('@/views/Admin/Overview.vue'),
    meta: { login: true }
  }, {
    path: '/admin/ingredients',
    name: 'Ingredient list',
    component: () => import('@/views/Admin/Ingredients.vue'),
    meta: { login: true }
  }, {
    path: '/admin/recipes',
    name: 'Recipe list',
    component: () => import('@/views/Admin/Recipes.vue'),
    meta: { login: true }
  }, {
    path: '/admin/recipes/:recipe',
    alias: '/admin/recipes/new',
    component: () => import('@/views/Admin/EditRecipe.vue'),
    meta: { login: true }
  }
]
