import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBreadSlice,
  faCarrot,
  faCheck,
  faCheese,
  faChevronDown,
  faDrumstickBite,
  faFish,
  faHeart,
  faHome,
  faLeaf,
  faPen,
  faPlusCircle,
  faSearch,
  faShareSquare,
  faTrashAlt,
  faUser,
  faUtensils,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import {
  faClock,
  faHeart as farHeart
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faUtensils,
  faHeart,
  farHeart,
  faHome,
  faUser,
  faCheck,
  faTrashAlt,
  faPen,
  faPlusCircle,
  faShareSquare,
  faClock,
  faChevronDown,
  faSearch,
  faLeaf,
  faDrumstickBite,
  faBreadSlice,
  faFish,
  faCheese,
  faCarrot,
  faChevronLeft,
  faChevronRight
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
