import axios from 'axios'
import store, { setAuth, clearAuth } from './store'
import Vue from 'vue'
import qs from 'query-string'

async function refreshToken () {
  if (!store.auth.refreshToken) throw new Error('no refresh token')

  try {
    const res = await apiClient.post('/api/auth/token', qs.stringify({
      refresh_token: store.auth.refreshToken,
      client_id: 'web',
      grant_type: 'refresh_token'
    }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })

    setAuth(res.data)
  } catch (e) {
    if (!e.response?.status) return

    clearAuth()
  }
}

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    accept: 'application/json'
  }
})

apiClient.interceptors.request.use((config) => {
  if (store.auth.accessToken) {
    config.headers.Authorization = `Bearer ${store.auth.accessToken}`
  }

  return config
})

apiClient.interceptors.response.use(null, async (error) => {
  if (!error.config || error.response?.status !== 401) {
    return Promise.reject(error)
  }

  try {
    await refreshToken()

    return apiClient.request(error.config)
  } catch (e) {
    clearAuth()

    Vue.$router.push('/admin/login')
  }
})

export default apiClient
export { refreshToken }
