<template>
  <div class="min-h-screen bg-secondary text-black relative flex flex-col">
    <Navbar v-if="showNavbar"/>
    <router-view/>
    <notifications />
    <div class="w-full flex items-center justify-center mt-auto mb-3 font-light">
      © Katrijn Droogmans
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  components: {
    Navbar
  },
  computed: {
    showNavbar () {
      return this.$route.name !== 'Soon'
    }
  }
}
</script>
