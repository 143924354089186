import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store.js'
import { refreshToken } from './http'

import './plugins.js'
import { init } from './mixins/analytics'

async function main () {
  init()

  if (store.auth.refreshToken) {
    await refreshToken()
  }

  new Vue({
    router,
    data: store,
    render: h => h(App)
  }).$mount('#app')
}

main()
