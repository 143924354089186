import http from '../http'
import { v4 as uuidv4 } from 'uuid'

const state = {
  uuid: null
}

async function init () {
  state.uuid = localStorage.getItem('uuid')

  if (state.uuid) return

  state.uuid = uuidv4()

  localStorage.setItem('uuid', state.uuid)

  await sendEvent('firstseen', Date.now().toString())
}

async function sendEvent (event, value, path) {
  await http.post('/api/event', {
    e: event,
    v: value,
    i: state.uuid,
    u: path
  })
}

export { init, sendEvent }
