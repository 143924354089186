import { sendEvent } from './mixins/analytics'

const store = {
  auth: {
    user: null,
    accessToken: null,
    refreshToken: localStorage.refreshToken ?? null
  },
  favorites: JSON.parse(localStorage.getItem('favorites')) ?? [],
  structuredData: false,
  showNutrition: false
}

export default store

export function setAuth (data) {
  localStorage.setItem('refreshToken', data.refresh_token)

  store.auth.user = data.user
  store.auth.accessToken = data.access_token
  store.auth.refreshToken = data.refresh_token
}

export function clearAuth () {
  localStorage.removeItem('refreshToken')

  store.auth.user = null
  store.auth.accessToken = null
  store.auth.refreshToken = null
}

export function addFavorite (recipe, path) {
  store.favorites.push(recipe.id)
  localStorage.setItem('favorites', JSON.stringify(store.favorites))
  sendEvent('like', recipe.id, path)
}

export function removeFavorite (recipe, path) {
  const index = store.favorites.indexOf(recipe.id)

  if (index < 0) return

  store.favorites.splice(index, 1)
  localStorage.setItem('favorites', JSON.stringify(store.favorites))
  sendEvent('dislike', recipe.id, path)
}
